<template>
  <div>
    <div class="columns is-multiline">
      <div class="column text-left is-6">
        <valid-currency-input
          :select="select"
          :rules="'currency|notcero|max_value:' + maximoPagar"
          :label="
            tipo == 'garantia' 
              ? 'Recuperación'
              : toggle_fields 
                ? 'Capital a pagar' 
                : 'Monto total a pagar'
          "
          placeholder="0.00"
          :expanded="true"
          v-model="innerConfig.monto"
          @select="setMontoPago"
        />
      </div>
      <div class="column text-left is-6">
        <valid-select
          rules="required"
          label="Moneda"
          placeholder="Selecciona uno..."
          v-model="innerConfig.moneda"
          fieldClass="has-text-grey-darker"
          :data="getMonedas"
          show="nombre"
          :expanded="true"
          realValue="id"
        />
      </div>
      <div class="column text-left is-6" v-if="innerConfig.moneda != 1">
        <valid-currency-input
          rules="currency"
          label="Tipo de cambio pactado"
          placeholder="0.00"
          :expanded="true"
          v-model="innerConfig.tipo_cambio_pactado"
        />
      </div>
      <div class="column text-left is-6">
        <valid-select
          rules="required"
          label="Instrumento monetario"
          placeholder="Seleccionar..."
          v-model="innerConfig.instrumento_monetario"
          :data="getInstrumentosMonetarios"
          show="nombre"
          realValue="id"
          :expanded="true"
        />
      </div>
      <div class="column text-left is-6">
        <valid-auto-complete
          label="Cuenta de banco"
          placeholder="Buscar..."
          v-model="innerConfig.cuenta_banco"
          :data="getCuentasBancarias"
          show="cuenta"
          :expanded="true"
          :custom="true"
        >
          <template v-slot:custom="slotProps">
            <div>
              <p class="has-text-grey-darker-always">
                Cuenta #{{ slotProps.option.option.cuenta }}
              </p>
              <p class="has-text-grey-darker-always">
                CLABE #{{ slotProps.option.option.CLABE }}
              </p>
              <p class="has-text-grey-darker-always">
                Institución #{{ slotProps.option.option.institucion.nombre }}
              </p>
            </div>
          </template>
        </valid-auto-complete>
      </div>
      <div class="column text-left is-6" v-if="(toggle_fields && tipo != 'garantia') || tieneRecuperacionExtraordinaria">
        <valid-currency-input
          rules="currency"
          :label="tipo == 'garantia' ? 'Recuperación extraordinaria' : 'Interés'"
          placeholder="0.00"
          :expanded="true"
          v-model="innerConfig.interes"
        />
      </div>
      <!-- <div class="column text-left is-6" v-if="toggle_fields">
        <valid-currency-input
          rules="currency"
          label="IVA"
          placeholder="0.00"
          :expanded="true"
          v-model="innerConfig.iva"
        />
      </div> -->
      <!-- <div class="column text-left is-4" v-if="toggle_fields">
        <valid-currency-input
          rules="currency"
          label="Devolución"
          placeholder="0.00"
          :expanded="true"
          v-model="innerConfig.devolucion"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidSelect from "@/components/form/ValidSelect";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import { mapGetters } from "vuex";
import { debounce } from "@/vendors/helpers";

export default {
  name: "ConfigGeneral",
  props: {
    value: Object,
    tiposSaldo: Array,
    condonaciones: Array,
    quitas: Array,
    id: Number,
    tipo: String,
    recuperacion_extraordinaria: Number,
  },
  data() {
    return {
      innerConfig: {
        monto: null,
        instrumento_monetario: null,
        cuenta_banco: "",
        moneda: null,
        tipo_cambio_pactado: null,
        interes: null,
        iva: null,
        devolucion: null
      },
      toggle_fields: false
    };
  },
  methods: {
    /*
      Función para cambiar el monto dependiendo de lo seleccionado
      @opcion (String) opción seleccionada del select del input de currency
    */
    setMontoPago(opcion) {
      switch (opcion) {
        case "Cubrir pago al día de hoy":
          this.innerConfig.monto = this.getDisposicion.pagar_deuda_hoy || this.getDisposicion.mantener_vigente_inhabil;
          break;
        case "Para cubrir lo vencido":
          this.innerConfig.monto = this.getDisposicion.estar_vigente;
          break;
        case "Para liquidar":
          if (this.tipo == "disposicion") {
            this.innerConfig.monto = parseFloat(this.getDisposicion.liquidar.toFixed(2));
          } else if (this.tipo == "cuentaCP") {
            this.innerConfig.monto = parseFloat(this.getCuentaCP.saldo.toFixed(2));
          } else if (this.tipo == "garantia") {
            this.toggle_fields = false;
            this.innerConfig.monto = parseFloat(this.getGarantia.saldo_capital.toFixed(2));
          }
          break;
        case "Pagar siguiente amortización":
          this.innerConfig.monto = this.getDisposicion.pago_siguiente_amortizacion;
          break;
        case "Otra cantidad":
          if(this.tipo == 'garantia'){
            this.toggle_fields = true;
          }else{
            this.toggle_fields = false;
          }
          this.innerConfig.monto = 0;
          break;
      }
    }
  },
  components: {
    ValidCurrencyInput,
    ValidSelect,
    ValidAutoComplete
  },
  computed: {
    ...mapGetters("catalogos", ["getInstrumentosMonetarios", "getMonedas"]),
    ...mapGetters("empresa", ["getCuentasBancarias"]),
    ...mapGetters("pagos", ["getDisposicion", "getCuentaCP", "getGarantia"]),
    monto() {
      return this.innerConfig.monto;
    },
    maximoPagar() {
      switch (this.tipo) {
        case "disposicion":
          return parseFloat(this.getDisposicion.saldos.total.toFixed(2));
        case "cuentaCP":
          return parseFloat(this.getCuentaCP.saldo.toFixed(2));
        case "garantia":
          return parseFloat(this.getGarantia.saldo_capital.toFixed(2));
        default:
          return 0;
      }
    },
    select() {
      const opcionesDisposicion = [
            "Cubrir pago al día de hoy",
            "Para cubrir lo vencido",
            "Para liquidar",
            "Otra cantidad"
      ]
      ;
      switch (this.tipo) {
        case "disposicion":    
          if (typeof this.getDisposicion.dias_antes_provision === 'number' && this.getDisposicion.pago_siguiente_amortizacion) {
            opcionesDisposicion.splice(3, 0, 'Pagar siguiente amortización');
          }
          return opcionesDisposicion;
        case "garantia":
          return ["Para liquidar", "Otra cantidad"];
        default:
          return ["Para liquidar", "Otra cantidad"];
      
      }
    },
    tieneRecuperacionExtraordinaria() {
      return this.tipo == 'garantia' && this.recuperacion_extraordinaria && this.recuperacion_extraordinaria > 0;
    }
  },
  watch: {
    innerConfig: {
      deep: true,
      handler(newVal) {
        this.$emit("input", newVal);
      }
    },
    value: {
      deep: true,
      handler(newVal) {
        this.innerConfig = newVal;
      }
    },
    /*
      Observa el monto y hace petición a API para obtener el desglose de pago simulado
    */
    monto: debounce(function(val) {
      if (val > 0 && this.tipo == "disposicion") {
        this.$store.dispatch("pagos/simular", {
          moneda_id: this.innerConfig.moneda,
          cantidad_pagada: val,
          tipo_cambio_pactado: this.innerConfig.tipo_cambio_pactado,
          orden_prelacion: this.tiposSaldo,
          condonaciones: this.condonaciones,
          quitas: this.quitas,
          credito_id: this.id
        });
      }
    }, 800)
  },
  created() {
    if (this.getInstrumentosMonetarios == null) {
      this.$store.dispatch("catalogos/getInstrumentosMonetarios");
    }
    if (this.getMonedas.length == 0) {
      this.$store.dispatch("catalogos/getMonedas");
    }
    if (this.getCuentasBancarias == null) {
      this.$store.dispatch("empresa/getCuentasBancarias");
    }
    if (this.value) {
      this.innerConfig = this.value;
    }
    if (this.tipo == "garantia") {
      this.toggle_fields = true;
    }
  }
};
</script>
